<template>
  <section class="vista-formulario">
    <div
      class="tarjeta-form animated fadeInUp"
    >
      <h1 class="h5 user-select-none">Global Trackit System</h1>
      <h3 class="my-3 user-select-none">Activar cuenta</h3>
      <form
        class="row justify-content-center"
        @submit.prevent="submitForm"
      >
        <p class="col-12 mb-n1 px-1 font-italic">
          Se ha enviado un código a tu correo, revísalo para confirmar la cuenta
        </p>
        <div
          :class="['col-12',
            {'campo': validarValores.codigo && validarValores.codigo.length},
            {'valido': validarValores.codigo === 'valido'},
            {'invalido': validarValores.codigo === 'invalido'}]"
        >
          <mdb-input
            v-model.trim="codigo"
            label="Ingresa el código"
            outline
          />
        </div>
        <mdb-btn
          type="submit"
          role="button"
          color="primario"
          :icon="botonDeshabilitado ? 'circle-notch' : 'check'"
          :icon-class="botonDeshabilitado ? 'fa-spin' : ''"
          :disabled="botonDeshabilitado"
          class="col-auto px-3"
        >
          Confirmar
        </mdb-btn>
      </form>
    </div>
    <AlertaMensaje
      :alerta-mensaje="alertaMensaje"
      @cerrar="alertaMensaje.contenido = ''"
    />
  </section>
</template>

<script>
import {
  mdbBtn,
  mdbInput
} from 'mdbvue'
import AlertaMensaje from '@/components/AlertaMensaje.vue'
import clienteVerificar from '@/graphql/clienteVerificar.gql'
export default {
  name: 'Activar',
  components: {
    mdbBtn,
    mdbInput,
    AlertaMensaje
  },
  data () {
    return {
      alertaMensaje: { contenido: '' },
      botonDeshabilitado: false,
      codigo: '',
      validarValores: {}
    }
  },
  methods: {
    submitForm () {
      this.validarValores = {
        codigo: this.codigo !== '' ? 'valido' : 'invalido'
      }
      if (this.codigo === '') {
        this.alertaMensaje = {
          contenido: 'Debes ingresar el código enviado al correo',
          tipo: 'error'
        }
        return
      }
      this.botonDeshabilitado = true
      this.$apollo
        .mutate({
          mutation: clienteVerificar,
          variables: {
            codigo: this.codigo,
            usuario: this.$route.params.usuario
          }
        })
        .then(({ data: { clienteVerificar } }) => {
          const { codigo } = clienteVerificar
          switch (codigo) {
            case 'Correcto':
              this.alertaMensaje = {
                contenido: 'Tu cuenta ha sido activada correctamente, puedes iniciar sesión en breve',
                tipo: 'correcto'
              }
              setTimeout(() => {
                this.$router.push('/login')
              }, 2500)
              break
            case 'Fallido':
              this.validarValores.codigo = 'invalido'
              this.alertaMensaje = {
                contenido: 'El código que ingresaste no es válido',
                tipo: 'error'
              }
              break
            case 'UsuarioInactivo':
              this.validarValores.codigo = 'invalido'
              this.alertaMensaje = {
                contenido: 'El código de verificación que ingresaste ha expirado, hemos enviado uno nuevo a tu correo',
                tipo: 'advertencia'
              }
              break
            default:
              this.validarValores.codigo = 'invalido'
              this.alertaMensaje = {
                contenido: 'Ha ocurrido un error inesperado',
                tipo: 'error'
              }
              break
          }
          this.botonDeshabilitado = false
          this.codigo = ''
        })
        .catch((e) => {
          this.alertaMensaje = {
            contenido: `Error: ${e}`,
            tipo: 'error'
          }
          this.codigo = ''
          this.validarValores = {}
          this.botonDeshabilitado = false
        })
    }

  }
}
</script>

<style lang="scss" scoped>
.vista-formulario {
  .tarjeta-form {height: auto;}
  .campo {margin-bottom: 0;}
}

</style>
